@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #050505;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  background-image: radial-gradient(ellipse at top right,
      rgba(249, 115, 22, 1) 0%,
      rgba(249, 115, 22, 0.9) 15%,
      rgba(154, 52, 18, 0.7) 30%,
      rgba(154, 52, 18, 0.5) 45%,
      rgba(249, 115, 22, 0.3) 60%,
      rgba(249, 115, 22, 0.1) 75%,
      rgba(3, 7, 18, 0) 100%);
  width: 200%;
  height: 100vh;
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
